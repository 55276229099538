
import { Options, Vue } from "vue-class-component";
import { ArithOpType } from "@/utils/Types";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "arith-op-block",
  props: {
    type: ArithOpType,
  },
  methods: {
    //Get the type of the operator to display
    getType(type: ArithOpType) {
      switch (type) {
        case ArithOpType.add:
          return "+";
        case ArithOpType.subtract:
          return "-";
        case ArithOpType.multiply:
          return "*";
        case ArithOpType.divide:
          return "/";
        case ArithOpType.mod:
          return "%";
        case ArithOpType.power:
          return "^";
        default:
          return "";
      }
    },
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
    clicked() {
      addExtra(this.type);
    },
  },
  computed: {
    checker() {
      return checkUsedBlock(this.type);
    },
  },
  mounted() {
    this.$refs.arithop.classList.add(this.type);
  },
})
export default class ArithOp extends Vue {}
