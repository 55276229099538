
import { Options, Vue } from "vue-class-component";
import VariableBlock from "@/components/game/psd/VariableBlock.vue";
import InstructionBlock from "@/components/game/psd/InstructionBlock.vue";
import * as types from "@/utils/Types";

@Options({
  props: {
    isPSD: Boolean,
    isRunning: Boolean,
    variables: [],
  },
  components: {
    VariableBlock,
    InstructionBlock,
  },
  methods: {
    passOnVariableCreation(
      varType: string,
      varName: string,
      optionsList: types.option[]
    ) {
      this.$refs.instructionBlock.addVariable(varType, varName, optionsList);
    },
  },
})
export default class SideBar extends Vue {}
