
import { Options, Vue } from "vue-class-component";
import json from "@/psdLang/lang.json";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "if-else-block",
  props: {},
  data() {
    return {
      lang: null,
      json: json,
    };
  },
  beforeMount() {
    this.lang = this.$store.getters.getIDELanguage;
  },
  methods: {
    /**
     * Get translation of item
     * @param s String to be translated
     */
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
    clicked() {
      addExtra("ifElse");
    },
  },
  computed: {
    checker() {
      return checkUsedBlock("ifElse");
    },
  },
})
export default class IfElse extends Vue {}
