
import { Options, Vue } from "vue-class-component";
import json from "@/psdLang/lang.json";
import { BinOpType } from "@/utils/Types";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "bin-op-block",
  beforeMount() {
    this.lang = this.$store.getters.getIDELanguage;
  },
  mounted() {
    let thisOp = this.$refs.binop;
    // Add binary operators on mount
    thisOp.classList.add(this.type);
    if (
      this.type == BinOpType.and ||
      this.type == BinOpType.or ||
      this.type == BinOpType.xor
    ) {
      thisOp.classList.add("boolOnly");
    } else if (
      this.type == BinOpType.gt ||
      this.type == BinOpType.lt ||
      this.type == BinOpType.lte ||
      this.type == BinOpType.gte
    ) {
      thisOp.classList.add("numOnly");
    }
  },
  props: {
    type: BinOpType,
  },
  data() {
    return {
      lang: null,
      json: json,
    };
  },
  methods: {
    //Get the type of the operator to display
    getType(type: BinOpType) {
      switch (type) {
        case BinOpType.eq:
          return "=";
        case BinOpType.neq:
          return "\u2260";
        case BinOpType.gt:
          return "\u003E";
        case BinOpType.lt:
          return "\u003C";
        case BinOpType.gte:
          return "\u2265";
        case BinOpType.lte:
          return "\u2264";
        case BinOpType.and:
          return this.getTranslation("and");
        case BinOpType.or:
          return this.getTranslation("or");
        case BinOpType.xor:
          return this.getTranslation("xor");
        default:
          return "";
      }
    },
    /**
     * Get translation of item
     * @param s String to be translated
     */
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
    clicked() {
      addExtra(this.type);
    },
  },
  computed: {
    checker() {
      return checkUsedBlock(this.type);
    },
  },
})
export default class BinOp extends Vue {}
