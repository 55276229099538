
import { Options, Vue } from "vue-class-component";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "in-op-block",
  props: {},
  methods: {
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
    clicked() {
      addExtra("infinite");
    },
  },
  computed: {
    checker() {
      return checkUsedBlock("bool");
    },
  },
})
export default class InfinOp extends Vue {}
