
import { Options, Vue } from "vue-class-component";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "par-op-block",
  props: {},
  methods: {
    clicked() {
      addExtra("brackets");
    },
  },
  computed: {
    checker() {
      return checkUsedBlock("brackets");
    },
  },
})
export default class ParOp extends Vue {}
