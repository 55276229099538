<template>
  <div
    class="draggable drag-4"
    :class="checker ? 'selected' : ''"
    @click="clicked"
  >
    <div class="assign">
      <!-- Block as shown in the instructions container -->
      <div class="preview">
        <em>x</em> := <em>y</em>
      </div>

      <!-- Block as shown in the PSD/IDE -->
      <div class="hidden codeBlock parent">
        <div class="blockEntry upperBorder var lhs"></div>
        <p>:=</p>
        <!-- Drag and drop zone -->
        <div class="blockEntry upperBorder value rhs"></div>
        <!-- Value input field -->
        <div class="var-input">
          <input type="text" class="hidden" autocomplete="off" />
          <select class="hidden"></select>
        </div>
        <div class="tooltip">
          <button class="button neutral hidden" style="display: none; pointer-events: none;">
            <img
              src="@/assets/images/icons/Switch.svg"
              alt="Switch between options and text icon"
            />
          </button>
          <div class="tooltipText top-6">
            <p>{{ $t("game.tooltips.psdSwitch") }}</p>
            <i></i>
          </div>
        </div>
        <div class="buttonsRight breakPoint">
          <div class="tooltip">
            <button class="button warning small hidden">
              <img
                src="@/assets/images/icons/Pause.svg"
                alt="Breakpoint icon"
              />
            </button>
            <div class="tooltipText left">
              <p>{{ $t("game.tooltips.removeBreakpoint") }}</p>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "as-op-block",
  props: {},
  methods: {
    clicked() {
      addExtra("assign");
    },
  },
  computed: {
    checker() {
      return checkUsedBlock("assignment");
    },
  },
})
export default class AsOp extends Vue {}
</script>
