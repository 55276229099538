
import { Options, Vue } from "vue-class-component";
import { VCodeMirror } from "vue3-code-mirror";
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/selection/active-line.js";

@Options({
  name: "code-area",
  data() {
    return {
      scriptLanguage: { name: "text/x-csharp", json: true },
      value: "",
      typedCode: "",
      previousLine: -1,
      breakpoints: {} as Record<string, boolean>,
      lines: 1,
    };
  },
  computed: {
    /**
     * Codemirror instance
     * @returns Codemirror editor
     */
    codemirror() {
      return this.$refs.cmCodeMirror.editor;
    },
    /**
     * Script option settings
     * @returns Script settings
     */
    scriptOptions: function () {
      return {
        tabSize: 4,
        indentUnit: 4,
        indentWithTabs: true,
        styleActiveLine: true,
        foldGutter: true,
        readOnly: this.run,
        gutters: ["breakpointGutter", "Codemirror-linenumber"],
      };
    },
  },
  watch: {
    /**
     * Check if the value for highlight has changed and highlight the new value, removing the previous highlight
     * @param val The id to highlight
     */
    highlight(val: number) {
      this.removeHighlight();
      this.addHighlight(val);
      this.previousLine = val;
    },
    /**
     * Check if run is false, because then all highlighting should be removed
     * @param val The value of run
     */
    run(val: boolean) {
      if (!val) {
        this.removeHighlight();
        this.previousLine = -1;
      }
    },
  },
  props: {
    run: Boolean,
    highlight: Number,
  },
  mounted() {
    //Add the gutterclick to the codemirror instance
    this.codemirror.on("gutterClick", this.handleGutterClick);
  },
  methods: {
    setCode(newValue: string) {
      // Sets the code in the CodeMirror to the provided string value
      this.typedCode = newValue;
      this.value = newValue;
      // Propperly indents the typed code.
      this.$nextTick(() => {
        this.codemirror.doc.setSelection(
          {
            line: this.codemirror.doc.firstLine(),
            ch: 0,
            sticky: null,
          },
          {
            line: this.codemirror.doc.lastLine(),
            ch: 0,
            sticky: null,
          },
          { scroll: false }
        );
        this.codemirror.indentSelection("smart");
      });
    },
    /**
     * Update breakpoints on code update
     * @param value Typed code
     */
    handleUpdateCode(value: string) {
      this.typedCode = value;
      let count = this.codemirror.lineCount();
      if (count != this.lines) {
        this.lines = count;
        this.updateBreakpoints();
      }
    },
    //Update code breakpoints
    updateBreakpoints: function () {
      let newRecord = {} as Record<string, boolean>;
      for (let i = 0; i < this.lines; i++) {
        let info = this.codemirror.lineInfo(i);
        if (info.gutterMarkers != null) newRecord[i] = true;
      }
      this.breakpoints = newRecord;
      //Tell game that the breakpoints have changed
      this.$emit("breakpointsChanged", this.breakpoints);
    },
    /**
     * Handle the addition and removal of breakpoints
     * @param n The line number where the user clicked
     */
    handleGutterClick(_: VCodeMirror, n: number) {
      let info = this.codemirror.lineInfo(n);
      let marker = this.makeBreakpoint();
      this.codemirror.setGutterMarker(
        n,
        "breakpointGutter",
        info.gutterMarkers ? null : marker
      );
      if (info.gutterMarkers != null) this.removeBreakpoint(n);
      else this.addBreakpoint(n);
    },
    /**
     * Create the breakpoint element to add to the gutter
     * @returns The breakpoint element
     */
    makeBreakpoint() {
      let marker = document.createElement("div");
      marker.innerText = "●";
      marker.classList.add("breakpoint");
      return marker;
    },
    /**
     * Remove the breakpoint at the given line
     * @param line The line of the breakpoint to remove
     */
    removeBreakpoint: function (line: number) {
      this.breakpoints[line.toString()] = false;
      this.$emit("breakpointsChanged", this.breakpoints);
    },
    /**
     * Add a breakpoint at the given line
     * @param line The line of the breakpoint to add
     */
    addBreakpoint: function (line: number) {
      this.breakpoints[line.toString()] = true;
      this.$emit("breakpointsChanged", this.breakpoints);
    },
    /**
     * Add highlight to the given line
     * @param val The line to highlight
     */
    addHighlight: function (val: number) {
      if (val != -1) {
        this.codemirror.addLineClass(val, "background", "highlight");
        this.codemirror.addLineClass(val, "gutter", "highlight");
      }
    },
    //Remove the highlight from the previous line
    removeHighlight: function () {
      if (this.previousLine != -1) {
        this.codemirror.removeLineClass(
          this.previousLine,
          "background",
          "highlight"
        );
        this.codemirror.removeLineClass(
          this.previousLine,
          "gutter",
          "highlight"
        );
      }
    },
  },
  components: {
    VCodeMirror,
  },
})
export default class CodeArea extends Vue {}
