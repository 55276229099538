
import { Options, Vue } from "vue-class-component";
import * as types from "@/utils/Types";
import json from "@/psdLang/lang.json";

@Options({
  data() {
    return {
      lang: null,
      json: json,
      selected: false,
    };
  },
  name: "variable-block",
  beforeMount() {
    this.lang = this.$store.getters.getIDELanguage;
  },
  mounted() {
    this.selected = window.location.pathname == "/docent/levelmaker";

    if (this.varData.worldVar) {
      let varBlock = this.$refs.var;
      varBlock.classList.add("worldVar");
    }
    //Retrieve this instance of the VarBlock and add the type to the classlist
    let thisVar = this.$refs.varItem;
    thisVar.classList.add(this.varData.type);

    //Update the drag and drop containers, so that this element can be dragged
    this.$emit("updateContainers");
  },
  props: {
    varData: {} as types.varType,
  },
  methods: {
    /**
     * Get translation of the name if it's a worldvar
     */
    getTranslatedName() {
      if (this.varData.worldVar) return this.json[this.lang][this.varData.name];
      return this.varData.name;
    },
  },
})
export default class VarBlock extends Vue {}
