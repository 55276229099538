
import { Options, Vue } from "vue-class-component";

@Options({
  name: "psd-block",
  props: {},
  mounted() {
    const sizeCheck = this.$store.getters.getLevelMakerAnswerBlocks.size > 0;
    if (window.location.pathname == "/docent/levelmaker" && sizeCheck) {
      let elem: HTMLElement | null = document.getElementById("psdDropZone");
      if (elem != null) {
        elem.innerHTML = this.$store.getters.getLevelMakerAnswerHTML;
      }
    }
  },
})
export default class PSDblock extends Vue {}
