
// Libraries
import { Options, Vue } from "vue-class-component";

// Components
import ButtonBlock from "@/components/game/ButtonBlock.vue";
import PSDBlock from "@/components/game/psd/PSDBlock.vue";
import CodeArea from "@/components/game/CodeArea.vue";

@Options({
  emits: [
    "changeMode",
    "runGame",
    "toggleSolution",
    "undo",
    "redo",
    "stopRunning",
    "setBreakpoint",
    "continue",
    "saveGame",
    "breakpointsChanged",
  ],
  props: {
    isPSD: Boolean,
    isRunning: Boolean,
    highlightId: Number,
    saveStatus: String,
    isEditor: Boolean,
  },
  components: {
    ButtonBlock,
    PSDBlock,
    CodeArea,
  },
})
export default class GameInputs extends Vue {}
