
import { Options, Vue } from "vue-class-component";
import dragula, { DragulaOptions } from "dragula";
import * as types from "@/utils/Types";
import * as wasm from "codeParser";
import { worldAPI } from "@/utils/apiCalls";
import { createPSDTree } from "@/utils/Functions";
import json from "@/psdLang/lang.json";

// Components
import GameInputs from "@/components/game/GameInputs.vue";
import SideBar from "@/components/game/SideBar.vue";
import { getOptimalSolution } from "@/utils/apiCalls/LevelAPI";

  @Options({
  props: {
    isPSD: Boolean,
    isRunning: Boolean,
    variables: Array,
    highlightId: Number,
    saveStatus: String,
    isEditor: Boolean,
  },
  data() {
    return {
      drake: {} as dragula.Drake,
      lang: null,
      json: json,
      instrContainers: [],
      psdContainers: [],
      condContainers: [],
      valContainers: [],
      varContainers: [],
      // Save & Load
      savedCode: "",
      isLookingAtSolution: false,
      autoSaver: null,
      // Undo/redo
      undoRedoStackPSD: [],
      pointer: -1,
      // The first 200 Id's are reserved for any code loaded in from a save.
      nextId: 200,
    };
  },
  beforeMount() {
    this.lang = this.$store.getters.getIDELanguage;
  },
  //Lifecycle hooks
  mounted() {
    this.drake = this.initializeDrake();
    //Add the correct containers
    this.updateContainers();
    // Load level save
    this.loadCode();
    // Create the autosaver which saves every 120 seconds (2 mins)
    this.createAutoSaver(120);
    // Add undo redo shortcut event listener
    document.addEventListener("keyup", this.shortcuts);
  },
  beforeUnmount() {
    // Remove the autosaver
    clearInterval(this.autoSaver);
    // Remove undo-redo shortcut eventlistener
    document.removeEventListener("keyup", this.shortcuts);
  },
  emits: [
    'changeAnswerMode',
    'droppedValue',
    'previewGame',
    'changeMode',
    'runGame',
    'stopRunning',
    'setBreakpoint',
    'continue',
    'breakpointsChanged'
  ],
  methods: {
    /**
     * Initialize the dragula instance named drake
     * @returns Drake
     */
    initializeDrake() {
      let drake: dragula.Drake;
      let dragOptions: DragulaOptions = {
        containers: [] as Element[],
        //Remove the element if it is dropped outside an allowed container
        removeOnSpill: true,
      };
      //Set the correct acceptance and copy functions
      dragOptions.accepts = this.checkAcceptance;
      dragOptions.copy = this.checkCopy;
      drake = dragula(dragOptions);
      drake
        //When an element is being dragged from source
        .on("drag", (el: Element, source: Element) => {
          // move the pointer up one for the stack
          this.pointer++;
          // Clear out the redo's at the top if there are any
          if (this.undoRedoStackPSD.length > this.pointer) {
            this.undoRedoStackPSD.length = this.pointer;
          }
          //Start adding to the stack
          let entry: types.PSDStackEntryMove = {
            type: "move",
            prevParent: source,
            nextParent: null,
            childNo: this.getChildIndex(el, source),
            nextChildNo: -1,
            movedElem: el,
          };
          this.undoRedoStackPSD[this.pointer] = entry;
          this.updateDrag(el, source);
        })
        //When an element was put back in its original space in container
        .on("cancel", (el: Element, container: Element) => {
          //Remove last addition to the stack since it got canceled.
          this.pointer--;
          this.undoRedoStackPSD.length--;

          if (container) {
            //Add full flag if conditional or value
            if (
              container.classList.contains("condition") ||
              container.classList.contains("value") ||
              container.classList.contains("var")
            ) {
              if (el.classList.contains("parop")) {
                if (container.parentElement?.classList.contains("boolOnly")) {
                  el.querySelector(".parent")?.classList.add("boolOnly");
                } else if (
                  container.parentElement?.classList.contains("numOnly")
                ) {
                  el.querySelector(".parent")?.classList.add("numOnly");
                }
              } else {
                let typeA = this.getType(el);
                if (typeA == "number") typeA = "num";
                else if (typeA == "text") typeA = "string";
                let typeB = this.getType(container.parentElement as Element);
                if (typeB == "" && typeA)
                  container.parentElement?.classList.add(typeA as string);
              }
              container.classList.add("full");
            }
            //Enable switch button if neccessary
            if (container.classList.contains("lhs")) {
              const btn = container.parentElement?.querySelector(
                ":scope > div > button"
              );
              btn?.classList.remove("hidden");
            }
          }
        })
        //When an element is cloned to be dragged
        .on("cloned", (clone: Element) => {
          //Change the shape of the block
          clone.classList.add("block-vis");
        })
        //When an element is dropped into target
        .on("drop", (el: Element, target: Element) => {
          //Add new Parent to undoRedoStack
          this.undoRedoStackPSD[this.pointer].nextParent = target;
          this.undoRedoStackPSD[this.pointer].nextChildNo = this.getChildIndex(
            el,
            target
          );
          this.undoRedoStackPSD[this.pointer].movedElem = el;
          this.updateDrop(el, target);
          this.updateContainers();
        })
        .on("dragend", () => {
          // Send update on placed for psd answer
          this.$emit("droppedValue");
        });
      return drake;
    },
    toggleSolution() {
      this.isLookingAtSolution = !this.isLookingAtSolution;
      if (this.isLookingAtSolution) {
        // Save the current code before switching
        this.saveCode();
        // Load the solution
        let levelInfo = this.$store.getters.getLevelInfo;
        getOptimalSolution(levelInfo.levelId).then((value) => {
          if (value != null) {
            let element: null | HTMLElement = document.getElementById(
              "psdBlock"
            )?.lastChild as HTMLElement;
            let outputString = wasm.toPsdAnswer(
              JSON.stringify(value),
              this.$store.getters.getIDELanguage
            );

            // array to make sure we don't translate back
            let translated: string[] = [];
            Object.keys(this.json[this.lang]).forEach((key) => {
              if (
                !translated.includes(key) &&
                outputString.includes("<p>" + key + "</p>")
              ) {
                translated.push(this.json[this.lang][key]);
                outputString = outputString.replaceAll(
                  "<p>" + key + "</p>",
                  "<p>" + this.json[this.lang][key] + "</p>"
                );
              }
            });

            element.innerHTML = outputString;
          }
        });
      } else {
        // Load level save
        let element: null | HTMLElement = document.getElementById("psdBlock")
          ?.lastChild as HTMLElement;
        element.innerHTML = "";
        this.loadCode();
      }
    },
    //Update the containers of dragula
    updateContainers() {
      this.instrContainers = [].slice.apply(
        document.querySelectorAll(".draggable")
      );
      this.psdContainers = [].slice.apply(document.querySelectorAll(".nested"));
      this.condContainers = [].slice.apply(
        document.querySelectorAll(".condition")
      );
      this.valContainers = [].slice.apply(document.querySelectorAll(".value"));
      this.varContainers = [].slice.apply(document.querySelectorAll(".var"));

      // Update the dragula containers
      this.drake.containers = this.instrContainers
        .concat(this.psdContainers)
        .concat(this.condContainers)
        .concat(this.valContainers)
        .concat(this.varContainers);
    },

    cleanupContainers() {
      document.querySelectorAll(".nested").forEach((element) => {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      });
      this.psdContainers = [];
      this.updateContainers();
    },
    /**
     * Get the input type of the value on the left hand side input field
     * @param lhsBlock The block that has been put in the left hand side input field
     */
    getType(lhsBlock: Element): string | null {
      if (
        lhsBlock.classList.contains("binop") ||
        lhsBlock.classList.contains("unop")
      ) {
        return "bool";
      } else if (lhsBlock.classList.contains("arithop")) {
        return "number";
      } else if (lhsBlock.classList.contains("options")) return "options";
      else if (lhsBlock.classList.contains("num")) return "number";
      else if (lhsBlock.classList.contains("string")) return "text";
      else if (lhsBlock.classList.contains("bool")) return "bool";
      else {
        return "";
      }
    },
    /**
     * Check if the block should accept a dragged element
     * @param el The block that is being dragged
     * @param target The container that the el is being dragged into
     */
    checkAcceptance(el: Element, target: Element) {
      if (target) {
        if (
          el?.classList.contains("binop") ||
          el?.classList.contains("unop") ||
          el?.classList.contains("arithop") ||
          el?.classList.contains("variable")
        ) {
          //Get the type of the dragged element and of the target element
          let typeA = this.getType(el);
          let typeB = this.getType(target.parentElement as Element);
          //If the type doesn't match and the type of the target isn't null don't allow this
          if (typeA != typeB && typeB != "") return false;
        }
        if (target.parentElement) {
          //Check the operators which only allow one type
          if (target.parentElement?.classList.contains("numOnly")) {
            if (
              !(this.getType(el) == "number") &&
              !el.classList.contains("parop")
            ) {
              return false;
            }
          }
          if (target.parentElement?.classList.contains("boolOnly")) {
            if (
              !(this.getType(el) == "bool") &&
              !el.classList.contains("parop")
            )
              return false;
          }
        }
        //If the dragged block is a binary operator, unary operator or parentheses, allow it in a conditional and value block
        if (
          el?.classList.contains("binop") ||
          el?.classList.contains("unop") ||
          el?.classList.contains("parop")
        ) {
          return (
            (this.condContainers.includes(target) ||
              this.valContainers.includes(target)) &&
            !target.classList.contains("full")
          );
        }
        //If the dragged block is a variable, allow it in a value block and in a variable block
        if (el?.classList.contains("variable")) {
          return (
            (this.valContainers.includes(target) ||
              this.varContainers.includes(target)) &&
            !target.classList.contains("full")
          );
        }
        //If the dragged block is an arithmetic operator, allow it only in a value block
        if (el?.classList.contains("arithop")) {
          return (
            this.valContainers.includes(target) &&
            !target.classList.contains("full")
          );
        }
        //If the dragged block is infinity, allow it to be only dragged into a conditional
        if (el?.classList.contains("inop")) {
          return (
            this.condContainers.includes(target) &&
            !target.classList.contains("full")
          );
        }
        //If it is none of these things, only allow it in the nested blocks
        return this.psdContainers.includes(target);
      } else return false;
    },
    /**
     * Update the surrounding containers when el has just been dragged out of the source container
     * @param el HTML element
     * @param source Source of HTML element
     */
    updateDrag(el: HTMLElement, source: HTMLElement) {
      source.classList.remove("full");
      //Remove switch stuff when variable is dragged out of the lhs
      if (source.classList.contains("lhs")) {
        const input = source.parentElement?.querySelector(
          ":scope > .var-input"
        ) as Element;
        const zone = source.parentElement?.querySelector(
          ":scope > .rhs"
        ) as Element;
        const btn = source.parentElement?.querySelector(
          ":scope > div > button"
        ) as Element;
        btn.classList.add("hidden");
        this.disableManualInput(zone, input);

        //Remove the type flag if the other side is empty
        if (el.classList.contains("parop")) {
          el.querySelector(".parent")?.classList.remove("boolOnly");
          el.querySelector(".parent")?.classList.remove("numOnly");
        } else {
          let otherSide = source.parentElement?.querySelector(".rhs");
          if (!otherSide?.classList.contains("full")) {
            let type = this.getType(source.parentElement as Element);
            if (type == "number") type = "num";
            else if (type == "text") type = "string";
            if (type) source.parentElement?.classList.remove(type as string);
          }
        }
      }
      if (source.classList.contains("rhs")) {
        //Remove the type flag if the other side is empty
        let otherSide = source.parentElement?.querySelector(".lhs");
        if (!otherSide?.classList.contains("full")) {
          let type = this.getType(source.parentElement as Element);
          if (type == "number") type = "num";
          else if (type == "text") type = "string";
          if (type) source.parentElement?.classList.remove(type as string);
        }
      }
    },
    /**
     * Update the surrounding containers when el has just been dropped in the target container
     * @param el HTML element
     * @param target Target HTML element
     */
    updateDrop(el: HTMLElement, target: HTMLElement) {
      //Add full flag if conditional or value
      if (el.id == "") {
        el.id = this.nextId;
        this.nextId++;
      }
      if (
        target.classList.contains("condition") ||
        target.classList.contains("value") ||
        target.classList.contains("var")
      ) {
        if (el.classList.contains("parop")) {
          if (target.parentElement?.classList.contains("boolOnly")) {
            el.querySelector(".parent")?.classList.add("boolOnly");
          } else if (target.parentElement?.classList.contains("numOnly")) {
            el.querySelector(".parent")?.classList.add("numOnly");
          }
        } else {
          let typeA = this.getType(el);
          if (typeA == "number") typeA = "num";
          else if (typeA == "text") typeA = "string";
          let typeB = this.getType(target.parentElement as Element);
          if (typeB == "" && typeA)
            target.parentElement?.classList.add(typeA as string);
        }
        target.classList.add("full");
      }
      //Enable the input switch button on the parent block
      if (
        target.classList.contains("lhs") &&
        (target.parentElement?.parentElement?.classList.contains("assign") ||
          target.parentElement?.parentElement?.classList.contains("binop") ||
          target.parentElement?.parentElement?.classList.contains("arithop")) &&
        !el.classList.contains("parop")
      ) {
        const parentBlock = target.parentElement.parentElement;
        let btn = parentBlock.querySelector(
          ":scope > div > div > button"
        ) as Element;

        btn.classList.remove("hidden");
        btn.removeEventListener("click", this.onToggle);
        btn.addEventListener("click", this.onToggle);

        const zone = parentBlock.querySelector(":scope > div >.rhs");
        const input = parentBlock.querySelector(":scope > div > .var-input");
        const lhs = parentBlock.querySelector(":scope > .parent > .lhs");

        const lhsBlock = parentBlock?.querySelector(".lhs")
          ?.firstChild as Element;
        let type: string | null = null;
        if (parentBlock) type = this.getType(lhsBlock);
        if (type && !zone?.classList.contains("full"))
          this.enableManualInput(
            zone as Element,
            input as Element,
            lhs as Element,
            type
          );
      }
      if (
        el.classList.contains("while") ||
        el.classList.contains("ifElse") ||
        el.classList.contains("assign")
      ) {
        //Add event listeners to these blocks for the sake of adding breakpoints
        el.removeEventListener("click", this.$parent.toggleBreakPoint);
        el.addEventListener("click", this.$parent.toggleBreakPoint);

        //Add event listeners to the breakpoint buttons so they can be removed
        let x = el.lastChild as Element;
        let bpBtn = x.querySelector(".breakPoint .button") as Element;
        bpBtn.removeEventListener("click", this.$parent.clickBreakPoint);
        bpBtn.addEventListener("click", this.$parent.clickBreakPoint);
      }
    },
    /**
     * Check if the element should be copied from source
     * @param source The source element which is being dragged on
     */
    checkCopy(_: Element, source: Element) {
      if (source) return this.instrContainers.includes(source);
      else return false;
    },
    /**
     * Switch from Manual input to drag and drop input
     * @param zone Input zone
     * @param input Input
     */
    disableManualInput(zone: Element, input: Element) {
      input.classList.add("hidden");
      (input.lastChild as HTMLDivElement).removeAttribute("varName");
      zone.classList.remove("hidden");

      //Determine the type we need to use
      const inputField = input?.querySelector("input");
      if (inputField) {
        if (inputField.value != "") {
          this.undoRedoStackPSD[this.pointer].constElem = inputField;
          this.undoRedoStackPSD[this.pointer].value = inputField.value;
        }
        inputField.setAttribute("type", "number");
        inputField.classList.add("hidden");
        inputField.removeEventListener("change", this.doStuff);
      }

      //Clear the dropdown menu
      const dropdown = input?.querySelector("select");
      if (dropdown) {
        if (dropdown.value != "") {
          this.undoRedoStackPSD[this.pointer].constElem = dropdown;
          this.undoRedoStackPSD[this.pointer].value = dropdown.value;
        }
        dropdown.classList.add("hidden");
        dropdown.innerHTML = "";
        dropdown.removeEventListener("change", this.doStuff);
      }
    },
    /**
     * Switch from drag and drop input to manual input
     * @param zone The rhs element for drag and drop input.
     * @param input The rhs element for manual input, containing both the input and select html elements.
     * @param lhs Left hand side
     * @param type Input type
     */
    enableManualInput(
      zone: Element,
      input: Element,
      lhs: Element,
      type: string
    ) {
      input.classList.remove("hidden");
      zone.classList.add("hidden");

      //Show the correct output depending to the type of the lhs
      if (type == "number") {
        const inputField = input?.querySelector("input");
        if (inputField) {
          inputField.setAttribute("type", "number");
          inputField.classList.remove("hidden");
          inputField.addEventListener("change", this.logValueChange);
          inputField.setAttribute("oldValue", inputField.value);
        }
      } else if (type == "text") {
        const inputField = input?.querySelector("input");
        if (inputField) {
          inputField.setAttribute("type", "text");
          inputField.classList.remove("hidden");
          inputField.addEventListener("change", this.logValueChange);
          inputField.setAttribute("oldValue", inputField.value);
        }
      } else if (type == "bool") {
        //Create options
        const trueOption = document.createElement("option");
        trueOption.innerText = "True";
        trueOption.value = "true";
        const falseOption = document.createElement("option");
        falseOption.innerText = "False";
        falseOption.value = "false";

        const dropdown = input?.querySelector("select");
        dropdown?.appendChild<HTMLOptionElement>(trueOption);
        dropdown?.appendChild<HTMLOptionElement>(falseOption);

        if (dropdown) {
          dropdown.classList.remove("hidden");
          dropdown.addEventListener("change", this.logValueChange);
          dropdown.value = trueOption.value;
          dropdown.setAttribute("oldValue", dropdown.value);
        }
      } else if (type == "options") {
        const dropdown = input?.querySelector("select");
        if (dropdown) {
          dropdown.classList.remove("hidden");
          dropdown.addEventListener("change", this.logValueChange);
          // Save the name of the variable this dropdown belongs to into the element
          dropdown.setAttribute(
            "varName",
            (
              ((lhs.firstChild as HTMLDivElement).lastChild as HTMLDivElement)
                .firstChild as HTMLParagraphElement
            ).innerText
          );

        }

        //Find options
        let optionDivs: Element[] = [].slice.call(
          lhs.querySelectorAll(
            ":scope > .options > .psdVar > div > .enumOptions > div"
          )
        );
        const isWorldVar = lhs
          .querySelectorAll(":scope > .options > .psdVar")
          .item(0)
          .classList.contains("worldVar");
        let options: string[] = optionDivs.map((x) => x.innerHTML);
        let oldValue: string | null = null;
        if (dropdown) oldValue = dropdown?.getAttribute("oldValue");

        //Fill dropdown with options
        options.forEach((option, index) => {
          let color = null;
          if (this.json["color"] && this.json["color"][option]) {
              color = this.json["color"][option]; // Assign color to the constant
          }
          console.log("OPTIONNN", option);
          console.log("color", color);
          const selectOption = document.createElement("option");

          if (color) {
            //selectOption.style.backgroundColor = color; 
          }
          selectOption.innerText = isWorldVar
            ? this.getTranslation(option)
            : option;
          selectOption.value = option;
          dropdown?.appendChild(selectOption);

          if (index == 0 && dropdown) {
              dropdown.value = selectOption.value;
              dropdown.setAttribute("oldValue", dropdown.value);
          }
        });
        if (oldValue && dropdown) dropdown.value = oldValue;
      }
    },
    /**
     * Handle the toggling of the input method
     * @param isUndo whether or not this action is part of an undo
     */
    onToggle: function (event: Event, isUndo = false) {
      let target = event.target as Element;

      if (!isUndo) {
        //Move the pointer up one for the stack
        this.pointer++;
        //Clear out the redo's at the top if there are any
        if (this.undoRedoStackPSD.length > this.pointer) {
          this.undoRedoStackPSD.length = this.pointer;
        }
        //Start adding to the stack
        let entry: types.PSDStackEntrySwitch = {
          type: "switch",
          toggleEvent: event,
          value: "",
          constElem: null,
        };
        this.undoRedoStackPSD[this.pointer] = entry;
      }

      if (target.tagName.toLowerCase() == "img")
        target = target.parentElement as Element;
      const parentBlock = target.parentElement?.parentElement?.parentElement;
      const lhsBlock = parentBlock?.querySelector(".lhs")
        ?.firstChild as Element;
      let type: string | null = null;
      if (parentBlock) type = this.getType(lhsBlock);
      if (parentBlock && type) this.toggleInput(parentBlock, type);
    },
    /**
     * Toggle the input field on the dragged element
     * Can't use vue due to the nature of Dragula
     * @param parentBlock The parent in which the lhs and rhs are contained
     * @param type The type of the lhs block
     * */
    toggleInput: function (parentBlock: HTMLElement, type: string) {
      //Find the input and drop zone to toggle
      const zone = parentBlock.querySelector(":scope > div > .rhs");
      const input = parentBlock.querySelector(":scope > div > .var-input");
      const lhs = parentBlock.querySelector(":scope > div > .lhs");
      if (input && zone && lhs) {
        if (zone?.classList.contains("hidden")) {
          this.disableManualInput(zone, input);
        } else {
          this.enableManualInput(zone, input, lhs, type);
        }
      }
    },
    /**
     * Return the index of the child inside of the parent, returns -1 if the parent doesnt contain the child
     * @returns Child index
     */
    getChildIndex(child: Element, parent: Element): number {
      return Array.prototype.indexOf.call(parent.children, child);
    },
    /**
     * Create and add a new entry to the undo-redo stack
     * @param event Log value change event
     */
    logValueChange(event: Event) {
      let target = event.target as HTMLInputElement | HTMLSelectElement;
      //Move the pointer up one for the stack
      this.pointer++;
      //Clear out the redo's at the top if there are any
      if (this.undoRedoStackPSD.length > this.pointer) {
        this.undoRedoStackPSD.length = this.pointer;
      }
      //Start creating the stack entry
      let oldValue = target.getAttribute("oldValue");
      if (oldValue == null) oldValue = "";
      let entry: types.PSDStackEntryValue = {
        type: "value",
        oldValue: oldValue,
        newValue: target.value,
        constElem: target,
      };
      //Add to the stack and update old value
      this.undoRedoStackPSD[this.pointer] = entry;
      target.setAttribute("oldValue", target.value);
    },
    //Undo for the PSD. Takes the entry that the pointer is currently pointing at and undoes that entry.
    undo() {
      let stackEntry: types.PSDStackEntry = this.undoRedoStackPSD[this.pointer];
      //Only do the function if there is anything to undo.
      if (stackEntry) {
        if (stackEntry.type == "move") {
          let entryMove: types.PSDStackEntryMove =
            stackEntry as types.PSDStackEntryMove;
          let prevParent = entryMove.prevParent;
          let childNo = entryMove.childNo;
          let movedElem = entryMove.movedElem;
          let currentParent = movedElem.parentNode;
          if (currentParent) {
            //Remove element from wherever it is now
            currentParent.removeChild(movedElem);
            this.updateDrag(movedElem, currentParent);
          }
          if (!prevParent.classList.contains("draggable"))
            //Add element to previous parent in the right spot
            prevParent.insertBefore(movedElem, prevParent.children[childNo]);
          this.pointer--;
          this.updateDrop(movedElem, prevParent);
          movedElem.classList.remove("gu-hide");
          this.updateContainers();
        } else if (stackEntry.type == "switch") {
          let entrySwitch: types.PSDStackEntrySwitch =
            stackEntry as types.PSDStackEntrySwitch;
          this.onToggle(entrySwitch.toggleEvent, true);
          if (entrySwitch.constElem)
            entrySwitch.constElem.value = entrySwitch.value;
          this.pointer--;
        } else if (stackEntry.type == "value") {
          let entryValue: types.PSDStackEntryValue =
            stackEntry as types.PSDStackEntryValue;
          let oldValue = entryValue.oldValue;
          let constElem = entryValue.constElem;
          constElem.value = oldValue;
          constElem.setAttribute("oldValue", oldValue);
          this.pointer--;
        }
        this.$emit("droppedValue");
      }
    },
    //Redo for the PSD. Takes the entry after the one that the pointer is currently pointing at and redoes that entry
    redo() {
      this.pointer++;
      let stackEntry: types.PSDStackEntry = this.undoRedoStackPSD[this.pointer];
      //Only do the function if there is anything to redo.
      if (stackEntry) {
        if (stackEntry.type == "move") {
          let entryMove: types.PSDStackEntryMove =
            stackEntry as types.PSDStackEntryMove;
          let nextParent = entryMove.nextParent;
          let nextChildNo = entryMove.nextChildNo;
          let movedElem = entryMove.movedElem;
          let currentParent = movedElem.parentNode;
          if (currentParent) {
            //Remove element from wherever it is now
            currentParent.removeChild(movedElem);
            this.updateDrag(movedElem, currentParent);
          }
          if (nextParent !== null) {
            //Add element to next parent in the right spot (if he has one)
            nextParent.insertBefore(
              movedElem,
              nextParent.children[nextChildNo]
            );
            this.updateDrop(movedElem, nextParent);
          }
          this.updateContainers();
        } else if (stackEntry.type == "switch") {
          //Switch back
          let entrySwitch: types.PSDStackEntrySwitch =
            stackEntry as types.PSDStackEntrySwitch;
          this.onToggle(entrySwitch.toggleEvent, true);
        } else if (stackEntry.type == "value") {
          //Put the value back to the value it had one step later.
          let entryValue: types.PSDStackEntryValue =
            stackEntry as types.PSDStackEntryValue;
          let newValue = entryValue.newValue;
          let constElem = entryValue.constElem;
          constElem.value = newValue;
          constElem.setAttribute("oldValue", newValue);
        }
        this.$emit("droppedValue");
      } else this.pointer--;
    },
    /**
     * Handle shortcuts for undo and redo
     * @param event Keyboard event
     */
    shortcuts(event: KeyboardEvent) {
      if (event.ctrlKey && event.code === "KeyZ") {
        this.undo();
      } else if (event.ctrlKey && event.code === "KeyY") {
        this.redo();
      }
    },
    //Load all variables and their values at this point
    loadVariables() {
      let instr = this.step() as types.atInstruction;
      let env = instr.env;
      if (env.length > 0) this.variables = env;
    },

    // ----------------------
    // | Saving and Loading |
    // ----------------------
    /**
     * Calls the parser to parse the code and
     * save it to the database
     * @param isAutosave if this is set to true then the saved message wont show up
     */
    saveCode: async function (isAutosave = false) {
      let progress: types.levelSave | null = null;
      let parsed: string | null = null;
      let saveObject: types.SaveObject | undefined =
        this.$store.getters.getLevelSave;
      if (this.isPSD) {
        parsed = createPSDTree();
        // Gather all the information about variables.
        let variableElement = document.getElementById("vars");
        let variables = undefined;
        if (variableElement) variables = variableElement.children;
        let parsedVariables: types.varType[] = [];
        if (variables)
          for (let index = 0; index < variables.length; index++) {
            let element: HTMLElement | undefined = undefined;
            let temp1 = variables.item(index);
            if (temp1) {
              let temp2 = temp1.firstChild as HTMLDivElement;
              if (temp2) element = temp2;
            }
            if (!element) continue;
            // Check if its a worldvar, and skip if it is since those will be loaded in anyway
            if (
              (element.lastChild as HTMLDivElement).classList.contains(
                "worldVar"
              )
            )
              continue;
            parsedVariables[index] = {
              name: element.children[0].innerHTML,
              type: element.classList[1],
              options: [],
              worldVar: false,
            };
            if (parsedVariables[index].type == "options") {
              let optionList: types.option[] = [];
              element.lastChild?.lastChild?.lastChild?.childNodes.forEach(
                (el, index) => {
                  optionList[index] = {
                    name: (el as HTMLDivElement).innerHTML,
                  };
                }
              );
              parsedVariables[index].options = optionList.filter(
                (value) => value.name != null
              );
            }
          }
        let listToString = "[";
        parsedVariables.forEach((thing) => {
          listToString += JSON.stringify(thing) + ",";
        });
        // Remove trailing ,
        listToString = listToString.slice(0, -1);
        listToString += "]";
        // If there were no items in the list then the [ got removed and we dont need to save any variables
        if (listToString !== "]")
          parsed =
            "{" +
            '"variableList": ' +
            listToString +
            ', "code": ' +
            parsed +
            "}";
        else parsed = "{" + '"code": ' + parsed + "}";
        // Wrap in a save object to combine with other saves.
        if (saveObject) saveObject.PSDSave = JSON.parse(parsed);
        else
          saveObject = {
            PSDSave: JSON.parse(parsed),
            typedSave: { code: "" },
          };
      } else {
        let input = this.$refs.gameInputs.$refs.codeBlock.typedCode;
        parsed = "{" + '"code": ' + JSON.stringify(input) + "}";
        // Wrap in a save object to combine with other saves.
        if (saveObject) saveObject.typedSave = JSON.parse(parsed);
        else
          saveObject = {
            PSDSave: { code: "" },
            typedSave: JSON.parse(parsed),
          };
      }

      progress = {
        levelId: this.$store.getters.getLevelInfo.levelId,
        bundleId: this.$store.getters.getBundle,
        code: JSON.stringify(saveObject),
      };

      // Send code to backend
      let success: number | null = null;
      if (parsed && progress.bundleId) {
        success = await worldAPI.saveGameProgress(progress);
      }

      if (success == 200 || success == 204) this.savedCode = "succeeded";
      else this.savedCode = "failed";
      if (isAutosave && this.savedCode != "failed") this.savedCode = "autosave";
      // Timer for the action success text
      setTimeout(() => {
        this.savedCode = "";
      }, 5000);
    },
    loadCode() {
      console.log("LOAD CODE GAMEPLAYAREA");
      console.log("instrContainers", this.instrContainers);
      console.log("psdContainers", this.psdContainers);
      console.log("condContainers", this.condContainers);
      console.log("isEditor", this.isEditor);
      console.log("getlevelmakeranswerHTML", this.$store.getters.getLevelMakerAnswerHTML);
      if (this.isEditor && this.$store.getters.getLevelMakerAnswerHTML) return;
      console.log("getlevelSave", this.$store.getters.getLevelSave);
      if (!this.$store.getters.getLevelSave) {
        this.$store.commit("setLevelSave", "");
        this.cleanupContainers();
      }
      // This try prevents the site from crashing in case the savedata is in any way corrupted
      try {
        let saveObject: types.SaveObject;
        if (this.isEditor) {
          saveObject = this.$store.getters.getLevelSave;
        } else {
          saveObject = this.$store.getters.getLevelSave;
        }
        console.log("SAVEOBJECT", saveObject);
        if (!saveObject) return;
        // Load in a PSD Save
        if (this.isPSD) {
          let savePacket: types.SavePacket = saveObject.PSDSave;
          let ASTSave = savePacket.code;
          let variableSave: types.varType[] = [];
          if (savePacket.variableList) variableSave = savePacket.variableList;
          // Load variables into the variable block
          variableSave.forEach((varObj) => {
            this.$refs.sideBar.passOnVariableCreation(
              varObj.type,
              varObj.name,
              varObj.options
            );
          });

          // The parser requires an object containing all names of enum variables with their possible values in a list
          // and also a list with the names of the world variables. Here we create those objects.
          let dropdownVariables: { [key: string]: [] } = {};
          let worldVariableNames: string[] = [];
          variableSave.forEach((varObj) => {
            let options: string[] = [];
            varObj.options.forEach((o) => {
              options.push(o.name);
            });
            if (options.length == 0) return;
            dropdownVariables = Object.defineProperty(
              dropdownVariables,
              varObj.name,
              {
                value: options,
                writable: true,
                enumerable: true,
                configurable: true,
              }
            );
          });
          // Also add the world variables to the list of enums
          let c = this.$store.getters.getWorldData.constants;
          this.$store.getters.getWorldData.variables.forEach(
            (wVar: { type: string; name: string }) => {
              worldVariableNames.push(wVar.name);
              let options: string[] = [];
              c.forEach((wCon: { type: string; name: string }) => {
                if (wCon.type == wVar.type) options.push(wCon.name);
              });
              dropdownVariables = Object.defineProperty(
                dropdownVariables,
                wVar.name,
                {
                  value: options,
                  writable: true,
                  enumerable: true,
                  configurable: true,
                }
              );
            }
          );
          // Get the psd container from the page and set it's contents to the result of the psd parser.
          let element: null | HTMLElement = document.getElementById("psdBlock")
            ?.lastChild as HTMLElement;
          if (element) {
            let htmlString = wasm.toPsd(
              JSON.stringify(ASTSave),
              this.$store.getters.getIDELanguage,
              JSON.stringify(dropdownVariables),
              JSON.stringify(worldVariableNames)
            );
            worldVariableNames.forEach((varname) => {
              htmlString = htmlString
                .split(">" + varname + "<")
                .join(">" + this.getTranslation(varname) + "<");
              dropdownVariables[varname].forEach((varvalue) => {
                htmlString = htmlString
                  .split(">" + varvalue + "<")
                  .join(">" + this.getTranslation(varvalue) + "<");
              });
            });
            element.innerHTML = htmlString;
            //Add event listeners for breakpoints
            element
              .querySelectorAll(".while, .ifElse, .assign")
              .forEach((el) => {
                //Add event listeners to these blocks for the sake of adding breakpoints
                el.removeEventListener("click", this.$parent.toggleBreakPoint);
                el.addEventListener("click", this.$parent.toggleBreakPoint);

                //Add event listeners to the breakpoint buttons so they can be removed
                let x = el.lastChild as Element;
                let bpBtn = x.querySelector(".breakPoint .button") as Element;
                bpBtn.removeEventListener(
                  "click",
                  this.$parent.clickBreakPoint
                );
                bpBtn.addEventListener("click", this.$parent.clickBreakPoint);
              });
            // add event listeners for switch buttons
            //element.querySelectorAll("button").forEach((btn) => {
            //  btn.addEventListener("click", this.onToggle);
            //});
            // add undo redo event listeners to dropdown fields
            element.querySelectorAll("select").forEach((dropdown) => {
              if (!dropdown.classList.contains("hidden")) {
                dropdown.addEventListener("change", this.logValueChange);
                let oldname = dropdown.getAttribute("oldvalue");
                if (oldname) dropdown.value = oldname;
              }
            });
            // add undo redo event listeners to input fields
            element.querySelectorAll("input").forEach((input) => {
              if (!input.classList.contains("hidden"))
                input.addEventListener("change", this.logValueChange);
            });
          }
          this.updateContainers();
        }
        // Load a typed save
        else {
          let savePacket: types.SavePacket = saveObject.typedSave;
          this.$refs.gameInputs.$refs.codeBlock.setCode(savePacket.code);
        }
      } catch (error) {
        console.log("Could not load level save. ");
        console.log(error);
        return;
      }
    },
    /**
     * Creates an autosaver that saves saves your code every X seconds
     * @param intervalInSeconds the ammount of seconds inbetween autosaves
     */
    createAutoSaver(intervalInSeconds: number) {
      this.autoSaver = setInterval(() => {
        if (!this.isLookingAtSolution) this.saveCode(true);
      }, intervalInSeconds * 1000);
    },
    // ----------------
    // | Highlighting |
    // ----------------

    /**
     * Highlight the block with the given id and remove the highlight on the previous block
     * @param id The id of the block to highlight
     */
    highlightBlock: function (id: string) {
      if (this.$parent.prevId != "") this.removeHighlight(this.$parent.prevId);
      if (id != "") {
        let el = document.getElementById(id) as Element;
        if (el.classList.contains("assign")) {
          this.highlightAssign(el, true);
        } else if (
          el.classList.contains("while") ||
          el.classList.contains("ifElse")
        ) {
          this.highlightIfElseWhile(el, true);
        } else {
          let parent = el.closest(".while, .ifElse, .assign") as Element;
          if (
            parent.classList.contains("while") ||
            parent.classList.contains("ifElse")
          ) {
            this.highlightIfElseWhile(parent, true);
          } else if (parent.classList.contains("assign")) {
            this.highlightAssign(parent, true);
          }
        }
        this.$parent.prevId = id;
      }
    },
    /**
     * Add or remove highlight on a given while or ifelse element
     * @param el The element to highlight
     * @param add Whether to add or remove the highlight
     */
    highlightIfElseWhile: function (el: Element, add: boolean) {
      let block = el.firstChild?.nextSibling?.nextSibling
        ?.nextSibling as Element;
      if (add) block.classList.add("selected");
      else block.classList.remove("selected");
    },
    /**
     * Add or remove highlight on a given assignment element
     * @param el The element to highlight
     * @param add Whether to add or remove the highlight
     */
    highlightAssign: function (el: Element, add: boolean) {
      let block = el.firstChild?.nextSibling?.nextSibling
        ?.nextSibling as Element;
      if (add) {
        el.classList.add("selected");
        block.classList.add("selected");
      } else {
        el.classList.remove("selected");
        block.classList.remove("selected");
      }
    },
    /**
     * Remove the highlighting from a block
     * @param id The id of the block to stop highlighting
     */
    removeHighlight: function (id: string) {
      if (id != "") {
        let el = document.getElementById(id) as Element;
        if (el.classList.contains("assign")) {
          this.highlightAssign(el, false);
        } else if (
          el.classList.contains("while") ||
          el.classList.contains("ifElse")
        ) {
          this.highlightIfElseWhile(el, false);
        } else {
          let parent = el.closest(".while, .ifElse, .assign") as Element;
          if (
            parent.classList.contains("while") ||
            parent.classList.contains("ifElse")
          ) {
            this.highlightIfElseWhile(parent, false);
          } else if (parent.classList.contains("assign")) {
            this.highlightAssign(parent, false);
          }
        }
      }
    },
    //Handle highlighting a new position in the instructions
    handleNewAt: function (id: string) {
      this.$parent.onforward = false;
      if (this.isPSD) {
        this.highlightBlock(id);
      } else {
        if (id != "") {
          let idInt = parseInt(id);
          this.$parent.highlighted = idInt;
        }
      }
    },
    /**
     * Get translation of item. Used for translating worldvar options.
     * @param s String to be translated
     */
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
  },
  components: {
    GameInputs,
    SideBar,
  },
})
export default class GamePlayArea extends Vue {}
