
import { Options, Vue } from "vue-class-component";
import router from "@/router";

@Options({
  name: "button-block",
  data() {
    return {
      userInfo: null,
      levelSettings: null,
      breakpointOn: false,
    };
  },
  beforeMount() {
    this.userInfo = this.$store.getters.getUserInfo;
    this.levelSettings = this.$store.getters.getLevelSettings;
  },
  computed: {
    /**
     * Check if both PSD and written code are allowed
     * @returns Boolean if PSD and written code allowed
     */
    psdAndCode: function () {
      return this.levelSettings.allowPsd && this.levelSettings.allowWritten;
    },
    /**
     * Check if user has teacher or admin permission
     * @returns Boolean has permission
     */
    teacherPermission: function () {
      return (
        this.userInfo?.permissionLevel == "teacher" ||
        this.userInfo?.permissionLevel == "admin"
      );
    },
  },
  props: {
    saved: String,
    editor: Boolean,
    levelMakerAnswer: Boolean,
    run: Boolean,
  },
  methods: {
    // go back to the level editor view
    gotoEditor() {
      this.$store.commit("turnPreviewOff");
      router.push("/docent/levelmaker");
    },
  },
})
export default class ButtonBlock extends Vue {}
