
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      mockVariable: {
        type: "idk",
        id: "1",
        env: [
          { name: "Richting", value: "Noord", type: "opties" },
          { name: "Vakje", value: "Gele ster", type: "opties" },
          { name: "RobbieBlij", value: "Waar", type: "bool" },
          { name: "Graskleur", value: "Groen", type: "tekst" },
        ],
      },
    };
  },
})
export default class VariableBlock extends Vue {}
