
import { Options, Vue } from "vue-class-component";
import * as PIXI from "pixi.js";
import TWEEN from "@tweenjs/tween.js";
import { generateWorld } from "@/utils/worldGeneration/default/WorldGen";
import {
  animInstruction,
  instructionBase,
  levelData,
  moveInstruction,
  rotInstruction,
  stateInstruction,
} from "@/utils/Types";
import { PropType } from "@vue/runtime-core";

import World from "@/utils/world/World";
import DefaultWorld from "@/utils/world/default/Default.world";
import {
  loadWorldSounds,
  loadGenericSounds,
  playSound,
  terminateSounds,
  ambientSounds,
} from "@/utils/AudioHandler";

@Options({
  data() {
    return {
      pixi: null as PIXI.Application | null,
      buffer: 4,
      worldMap: undefined,
      leveldata: null,
      world: null as World | null,
      theme: "farm",
      activeActions: -1,
      animationTime: 1000,
      canvas: HTMLCanvasElement,
      isDragging: false,
      curX: -1,
      curY: -1,
      prevX: -1,
      prevY: -1,
    };
  },
  watch: {
    //Update step when new instruction is set
    step: function (newVal) {
      if (newVal) {
        this.rmEventListeners();
        this.processStep();
      } else this.activeActions = -1;
    },
    //Stop flag
    stopFlag: function (newVal) {
      if (newVal == true) {
        this.world.reset();
        this.setEventHandlers();
      }
    },
  },
  beforeMount() {
    this.leveldata = this.$store.getters.getLevelInfo.levelData;
    this.theme = this.leveldata.theme;
  },
  mounted() {
    //Mount a pixi js instance in the DOM
    this.initPixi();
    window.onresize = this.updateSize;
  },
  unmounted() {
    terminateSounds();
    const app = this.pixi as PIXI.Application;
    app.ticker.remove(this.update);
    this.rmEventListeners();
  },
  methods: {
    initPixi() {
      this.canvas = document.getElementById("gameView") as HTMLCanvasElement;
      const app = new PIXI.Application({
        backgroundAlpha: 0,
        view: this.canvas,
        antialias: true,
      });
      this.pixi = app;
      PIXI.Loader.shared.destroy();
      this.setupWorld();
      this.setEventHandlers();
      this.loadSounds();
      this.updateSize();
      app.ticker.add(this.update);
    },

    // Setup eventhandlers
    setEventHandlers() {
      this.canvas.addEventListener("mousedown", this.startDrag);
      this.canvas.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.endDrag);
      this.canvas.addEventListener("wheel", this.handleWheelZoom);
    },
    //Remove all event listeners
    rmEventListeners() {
      this.canvas.removeEventListener("mousedown", this.startDrag);
      this.canvas.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.endDrag);
      this.canvas.removeEventListener("wheel", this.handleWheelZoom);
    },
    /**
     * Zoom on mouse wheel
     * @param e Mouse wheel event
     */
    handleWheelZoom(e: WheelEvent) {
      if (e.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    /**
     * Start dragging grid
     * @param event Mouse move event
     */
    startDrag(event: MouseEvent) {
      let btn = event.button;
      if (btn == 0) {
        this.isDragging = true;
        this.curX = event.offsetX;
        this.curY = event.offsetY;
      }
    },
    /**
     * Drag grid
     * @param event Mouse move event
     */
    drag(event: MouseEvent) {
      let app: PIXI.Application = this.pixi;
      let mid = new PIXI.Point(app.renderer.width / 2, app.renderer.height / 2);

      if (this.isDragging == true) {
        this.isDragging = true;
        this.prevX = this.curX;
        this.prevY = this.curY;
        this.curX = event.offsetX;
        this.curY = event.offsetY;

        let offX = event.movementX;
        let offY = event.movementY;
        let tx = offX / app.stage.scale.x;
        let ty = offY / app.stage.scale.y;

        mid = app.stage.transform.localTransform.applyInverse(mid);
        if (mid.x - offX > app.renderer.width || mid.x - offX < 0) {
          tx = 0;
        }
        if (mid.y - offY > app.renderer.height || mid.y - offY < 0) {
          ty = 0;
        }

        let m = PIXI.Matrix.IDENTITY.translate(tx, ty);
        let res: PIXI.Matrix = this.pixi.stage.localTransform.append(m);
        app.stage.transform.setFromMatrix(res);
      }
    },
    //End dragging grid
    endDrag() {
      this.isDragging = false;
    },
    //Update grid size
    updateSize() {
      let width = this.canvas.getBoundingClientRect().width;
      let height = this.canvas.getBoundingClientRect().height;
      this.canvas.width = width;
      this.canvas.height = height;
      this.pixi.screen.width = width;
      this.pixi.screen.height = height;
    },
    //Update frame for animations
    update() {
      function animate(time: number) {
        TWEEN.update(time);
      }
      requestAnimationFrame(animate);

      //Get next step if animations have finished
      if (this.hasFinishedActions()) {
        if (this.stopFlag) {
          //No more highlighting
          this.world.reset();
          this.$emit("reset");
        }
        this.$emit("stepFinish");
      }
    },
    //Set up game world
    setupWorld() {
      console.log("SETUPWORLD")
      const worldMap = generateWorld(
        this.leveldata as levelData,
        30,
        0.15,
        8,
        4,
        3,
        this.buffer
      );
      this.worldMap = worldMap;
      const canvas: HTMLCanvasElement = document.getElementById(
        "gameView"
      ) as HTMLCanvasElement;
      const w = new DefaultWorld(
        worldMap,
        this.leveldata as levelData,
        this.buffer,
        this.pixi,
        canvas,
        this.theme
      );
      this.world = w;
      console.log("SETUPWORLD END", w)

    },
    //Draw start of the world
    drawWorldStart() {
      const w = this.world as World;
      w.render();
    },
    //Processes all the instructions in the current step
    processStep() {
      this.activeActions = 0;
      this.handleInstruction(this.step);
    },
    /**
     * Figures out how to process the given instruction
     * @param instr Instruction to be processed
     */
    handleInstruction(instr: instructionBase) {
      this.activeActions += 1;
      switch (instr.name) {
        case "at":
          if (instr.id != "") this.$emit("position", instr.id);
          if (this.breakPointList[instr.id]) {
            this.$emit("breakpoint");
          }
          this.activeActions -= 1;
          break;
        case "MoveTo":
          this.moveTo(
            (instr as moveInstruction).entityID,
            (instr as moveInstruction).targetPosition
          );
          break;
        case "Rotate":
          this.rotate(
            (instr as rotInstruction).entityID,
            (instr as rotInstruction).degrees
          );
          break;
        case "PlayAnim":
          this.playAnim(
            (instr as animInstruction).entityID,
            (instr as animInstruction).anim
          );
          if ((instr as animInstruction).anim == "death") {
            playSound("Crash", false, 0.5);
          }
          break;
        case "SetEntityState":
          this.setEntityState(
            (instr as stateInstruction).entityID,
            (instr as stateInstruction).state,
            (instr as stateInstruction).value
          );
          break;
        default:
          this.activeActions -= 1;
          break;
      }
    },
    /**
     * Moves an entity according to the instruction
     * @param id The id of the entity to move
     * @param targetPosition Where to move the entity to
     */
    moveTo(id: string, targetPosition: [x: number, y: number]) {
      //Calculate target position
      const targetIsoPosition = this.world.getIsometricPosition(
        this.canvas,
        this.world.character.sprite.width,
        {
          x: targetPosition[0] + this.buffer,
          y: targetPosition[1] + this.buffer,
        }
      );
      //Force the zoom factor
      let zoom = this.pixi.stage.scale.x < 4 ? 4 / this.pixi.stage.scale.x : 1;
      this.zoom(zoom);
      let point = this.pixi.stage.localTransform.applyInverse(
        new PIXI.Point(
          this.pixi.renderer.width / 2,
          this.pixi.renderer.height / 2
        )
      );
      //Calculate the camera offset
      let dx = point.x - targetIsoPosition.x;
      let dy = point.y - targetIsoPosition.y;

      //Change position
      new TWEEN.Tween(this.world.character.sprite)
        .to(
          { x: targetIsoPosition.x, y: targetIsoPosition.y },
          this.animationTime
        )
        .easing(TWEEN.Easing.Cubic.InOut)
        .start()
        .onComplete(() => (this.activeActions -= 1));
      //Update camera position
      new TWEEN.Tween(this.pixi.stage.transform.position)
        .to(
          {
            x:
              this.pixi.stage.transform.position.x +
              dx * this.pixi.stage.scale.x,
            y:
              this.pixi.stage.transform.position.y +
              dy * this.pixi.stage.scale.y,
          },
          this.animationTime
        )
        .easing(TWEEN.Easing.Cubic.InOut)
        .start();
    },
    /**
     * Move an entity according to the instruction
     * @param id The id of the entity to rotate
     * @param degrees degrees to rotate (in degrees)
     */
    rotate(id: string, degrees: number) {
      console.debug(`Rotating entity ${id} ${degrees} degrees`);

      this.world.character.direction += degrees;
      this.world.character.direction %= 360;

      const newDirection = this.world.getDirFromRotation(
        this.world.character.direction
      );

      this.world.character.sprite.texture =
        this.world.character.textures[newDirection];

      //Decrease action counter on finish
      this.activeActions -= 1;
    },
    /**
     * Moves an entity according to the instruction
     * @param id The id of the entity to play the animation on
     * @param anim The animation to play
     */
    playAnim(id: string, anim: string) {
      console.debug(`Playing ${anim} on entity ${id}`);
      //Decrease action counter on finish
      this.activeActions -= 1;
    },
    /**
     * Move an entity according to the instruction
     * @param id The id of the entity to change the state on
     * @param state The state to change
     * @param value The value to change the state to
     */
    setEntityState(id: string, state: string, value: string) {
      console.debug(`Setting state.${state} to ${value} on entity ${id}`);
      //Decrease action counter on finish
      this.activeActions -= 1;
    },
    /**
     * Whether or not all the actions such as animations have finished
     * @returns true for if finished
     */
    hasFinishedActions(): boolean {
      return this.activeActions == 0;
    },
    //Zoom in grid
    zoomIn() {
      let app: PIXI.Application = this.pixi;
      let zoomFactor = 1.2;
      let zoom = app.stage.scale.x * zoomFactor < 4 ? zoomFactor : 1;
      this.zoom(zoom);
    },
    //Zoom out grid
    zoomOut() {
      let app: PIXI.Application = this.pixi;
      let zoomFactor = 0.8;
      let zoom = app.stage.scale.x * zoomFactor > 0.5 ? zoomFactor : 1;
      this.zoom(zoom);
    },
    //Zoom grid
    zoom(zoom: number) {
      let app: PIXI.Application = this.pixi;
      let point = new PIXI.Point(
        app.renderer.width / 2,
        app.renderer.height / 2
      );

      //Create view matrix
      point = app.stage.localTransform.applyInverse(point);
      let viewMatrix = PIXI.Matrix.IDENTITY;
      let tx = point.x;
      let ty = point.y;
      viewMatrix.translate(-tx, -ty).scale(zoom, zoom).translate(tx, ty);

      //Apply zoom
      let res = app.stage.localTransform.append(viewMatrix);
      app.stage.transform.setFromMatrix(res);
    },
    //Load game sounds
    loadSounds() {
      for (let s of this.world?.theme.sounds) {
        loadWorldSounds(s.name, s.url, s.ambient);
      }
      loadGenericSounds();
      playSound("Background", true, 0.2);
      ambientSounds();
    },
  },
  props: {
    step: Object as PropType<instructionBase>,
    stopFlag: Boolean,
    breakPointList: {} as Record<string, boolean>,
  },
})
export default class GameView extends Vue {}
