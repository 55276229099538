
import { Options, Vue } from "vue-class-component";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "as-op-block",
  props: {},
  methods: {
    clicked() {
      addExtra("assign");
    },
  },
  computed: {
    checker() {
      return checkUsedBlock("assignment");
    },
  },
})
export default class AsOp extends Vue {}
