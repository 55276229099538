
import { Options, Vue } from "vue-class-component";
import json from "@/psdLang/lang.json";
import { UnOpType } from "@/utils/Types";
import { addExtra, checkUsedBlock } from "@/utils/Functions";

@Options({
  name: "un-op-block",
  props: {
    type: UnOpType,
  },
  data() {
    return {
      lang: null,
      json: json,
    };
  },
  methods: {
    /**
     * Get the type of the operator to display
     * @param type Unary operator type
     */
    getType(type: UnOpType) {
      switch (type) {
        case UnOpType.not:
          return this.getTranslation("not");
        default:
          return "";
      }
    },
    /**
     * Get translation of item
     * @param s String to be translated
     */
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
    getValue(): string {
      // Now only returns unOp, because unOp json does not say which unop it is (we only use not to this point)
      switch (this.type) {
        case UnOpType.not:
          return "unOp";
        default:
          return "";
      }
    },
    clicked() {
      addExtra(this.getValue());
    },
  },
  computed: {
    checker() {
      let value: string = this.getValue();
      return checkUsedBlock(value);
    },
  },
  beforeMount() {
    this.lang = this.$store.getters.getIDELanguage;
  },
  mounted() {
    this.$refs.unop.classList.add(this.type);
  },
})
export default class UnOp extends Vue {}
