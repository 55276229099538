
// import all components
import { Options, Vue } from "vue-class-component";
import { getWorldData } from "@/utils/apiCalls/WorldAPI";
import While from "@/components/game/psd/codeBlocks/While.vue";
import IfElse from "@/components/game/psd/codeBlocks/IfElse.vue";
import AsOp from "@/components/game/psd/operatorBlocks/AssignmentOperator.vue";
import ParOp from "@/components/game/psd/operatorBlocks/ParentheseOperator.vue";
import BinOp from "@/components/game/psd/operatorBlocks/BinaryOperator.vue";
import UnOp from "@/components/game/psd/operatorBlocks/UnaryOperator.vue";
import ArithOp from "@/components/game/psd/operatorBlocks/ArithmeticOperator.vue";
import InfinOp from "@/components/game/psd/operatorBlocks/InfiniteOperator.vue";
import VarBlock from "@/components/game/psd/variableBlocks/VariableBlock.vue";
import * as types from "@/utils/Types";
import json from "@/psdLang/lang.json";

@Options({
  data() {
    return {
      optionBlock: "num",
      varName: "",
      newOption: "",
      isEnum: false,
      optionsList: [] as types.option[],
      lang: null,
      json: json,
      neededInstr: {},
      showExtra: false,
      answerMode: false,
    };
  },
  beforeMount() {
    if (window.location.pathname == "/docent/levelmaker") {
      this.showExtra = true;
      this.answerMode = true;
      getWorldData("robbie");
    } else {
      this.setInstructionNeeds();
    }
    this.lang = this.$store.getters.getIDELanguage;
  },
  computed: {
    /**
     * Get variable list
     * @returns Variable list
     */
    varList() {
      let res = [];
      const c = this.$store.getters.getWorldData.constants;
      for (
        let i = 0;
        i < this.$store.getters.getWorldData.variables.length;
        i++
      ) {
        const v = this.$store.getters.getWorldData.variables[i];
        let x: types.varType = {
          name: v.name,
          type:
            v.type != "num" && v.type != "string" && v.type != "bool"
              ? "options"
              : v.type,
          options: [],
          worldVar: true,
        };
        if (x.type == "options") {
          for (let j = 0; j < c.length; j++) {
            if (c[j].type == v.type) x.options.push(c[j]);
          }
        }
        res.push(x);
      }
      return res;
    },
    /**
     * Switch dropdown arrow
     * @returns Dropdown arrow
     */
    extraText() {
      return this.showExtra ? "▲" : "▼";
    },
    /**
     * Switch extra tooltip
     * @returns Show or collapsed text
     */
    extraTooltip() {
      return this.showExtra ? "extraShow" : "extraCollapsed";
    },
  },
  methods: {
    //Set needed instructions
    setInstructionNeeds() {
      const needed =
        this.$store.getters.getLevelInfo.levelData.levelInstructions.needed;
      //Loop through needed functions
      for (let i = 0; i < needed.length; i++) {
        this.neededInstr[needed[i].name] = true;
      }
    },
    //Toggle extra instructions
    toggleExtras() {
      this.showExtra = !this.showExtra;
      this.$nextTick(() => this.$emit("updateContainers"));
    },
    /**
     * Add a variable to the variables that can be chosen
     * @param varType The type the new variable has
     * @param varName The variable name
     * @param optionsList A list containing all the possible options in case the variable is an enum
     */
    addVariable(
      this: {
        optionBlock: string;
        varName: string;
        optionsList: types.option[];
        varList: types.varType[];
        isEnum: boolean;
        checkOptionsList(): void;
        checkVariableName(name: string): boolean;
      },
      varType: string,
      varName: string,
      optionsList: types.option[]
    ) {
      // Clear the name input field
      this.varName = "";
      //Create a new variable with the input the user gave
      let newVar: types.varType = {
        name: varName,
        type: varType,
        options: [],
        worldVar: false,
      };
      //Check if the variable is unique
      if (
        varName == "" ||
        varName[0] == " " ||
        this.checkVariableName(varName)
      ) {
        console.log("Error: variable name is not valid");
      } else {
        if (varType == "options") {
          this.checkOptionsList();
          newVar.options = optionsList;
          this.optionsList = [];
          this.isEnum = false;
        }
        this.optionBlock = "num";
        this.varList.push(newVar);
      }
      (this as unknown as Vue).$forceUpdate();
    },
    /**
     * Check if the variable name already exists
     * @returns Boolean variable exists
     */
    checkVariableName: function (name: string) {
      for (let i = 0; i < this.varList.length; i++) {
        if (this.varList[i].name == name) return true;
      }
      return false;
    },
    //Allow the user to add options to an enum
    createEnum: function () {
      if (this.optionBlock == "options") this.isEnum = true;
      else this.isEnum = false;
    },
    //Add a new option to enum
    addOption: function () {
      let text = this.newOption;
      if (text[0] == " " || text == "" || this.checkOptionName(text)) {
        console.log("Error: option name is invalid");
      } else {
        this.optionsList.push({ name: text });
      }
      this.newOption = "";
    },
    //Remove option from the list
    removeOption: function (i: number) {
      this.optionsList.splice(i, 1);
    },
    /**
     * Check the validity of option name
     * @returns Boolean option name exists
     */
    checkOptionName: function (name: string, index: number) {
      for (let i = 0; i < this.optionsList.length; i++) {
        if (this.optionsList[i].name == name && index != i) return true;
      }
      return false;
    },
    //Check the options list for invalid options
    checkOptionsList: function () {
      let invalid: number[] = [];
      for (let i = 0; i < this.optionsList.length; i++) {
        if (
          this.optionsList[i].name.length == 0 ||
          this.checkOptionName(this.optionsList[i].name, i)
        ) {
          invalid.push(i);
        }
      }
      for (let n = 0; n < invalid.length; n++) {
        this.optionsList.splice(invalid[n] - n, 1);
      }
    },
    /**
     * Get translation of item
     * @param s String to be translated
     */
    getTranslation(s: string) {
      return this.json[this.lang][s];
    },
  },
  components: {
    While,
    IfElse,
    AsOp,
    ParOp,
    BinOp,
    UnOp,
    ArithOp,
    InfinOp,
    VarBlock,
  },
})
export default class InstructionBlock extends Vue {}
